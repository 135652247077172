<div class="navbar-area" [dir] = "directionController.textDirection">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/logo1.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav" >
        <div class="container-fluid">
            <nav class="container-max navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo1.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto" *ngIf="othermenu == ''">
                       
                        <li class="nav-item"><a (click)="scrollToId('Industries')"  class="nav-link">{{'menu_1' | translate }} </a></li>
                        <li class="nav-item"><a (click)="scrollToId('Solutions')"  class="nav-link">{{'menu_2' | translate }} </a></li>
                        <li class="nav-item"><a (click)="scrollToId('What_we_do')"  class="nav-link">{{'menu_3' | translate }} </a></li>
                        <li class="nav-item"><a (click)="scrollToId('About_Zik')"  class="nav-link">{{'menu_4' | translate }} </a></li>
                        <li class="nav-item"><a routerLink="/blog" class="nav-link">{{'blog' | translate }}   </a></li> 

                    
                    </ul>

                    <ul class="navbar-nav m-auto" *ngIf="othermenu != ''">
                       
                        <li class="nav-item"><a routerLink="/" (click)="MovetoSection('Industries')"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" >{{'menu_1' | translate }} </a></li>
                        <li class="nav-item"><a routerLink="/" (click)="MovetoSection('Solutions')"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" >{{'menu_2' | translate }} </a></li>
                        <li class="nav-item"><a routerLink="/" (click)="MovetoSection('What_we_do')"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"  >{{'menu_3' | translate }} </a></li>
                        <li class="nav-item"><a routerLink="/" (click)="MovetoSection('About_Zik')" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{'menu_4' | translate }} </a></li>
                        <li class="nav-item"><a routerLink="/blog" class="nav-link">{{'blog' | translate }}  </a></li>

                    
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        
                        <div class="side-item">
                            <div class="nav-add-btn" *ngIf="othermenu == ''">
                                <a (click)="scrollToId('Request_a_demo')" class="default-btn border-radius">{{'request_meeting' | translate }} </a>
                            </div>

                            <div class="nav-add-btn" *ngIf="othermenu != ''">
                                <a  routerLink="/" (click)="MovetoSection('Request_a_demo')" class="default-btn border-radius">{{'request_meeting' | translate }} </a>
                            </div>
                        </div>

                        
                        <div class="side-item">
                            <div class="user-btn custom-select-option ">
                                <select 
                                class="form-control" 
                                #selectedLang 
                                (change)="switchLang(selectedLang.value)">
                                <option *ngFor="let language of translate.getLangs()" 
                                [value]="language"
                                [selected]="language === currentlang">
                                    
                                    {{ ((language == 'il')?'עברית':'English') }}
                                    </option>
                                </select>
                            </div>
                            
                        </div>
                    </div>

                 
                </div>
            </nav>
        </div>
    </div>

    <div  class="side-nav-responsive">
        <div  class="container">
           <div  class="dot-menu">
              <div  class="circle-inner">
                 <div  class="circle circle-one"></div>
                 <div  class="circle circle-two"></div>
                 <div  class="circle circle-three"></div>
              </div>
           </div>
           <div  class="container">
              <div  class="side-nav-inner">
                 <div  class="side-nav justify-content-center align-items-center">
                    <div  class="side-item">
                        <div class="user-btn custom-select-option ">
                            <select 
                            class="form-control" 
                            #selectedLang 
                            (change)="switchLang($event.target.value)">
                            <option *ngFor="let language of translate.getLangs()" 
                            [value]="language"
                            [selected]="language === currentlang">
                                
                                {{ ((language == 'il')?'עברית':'English') }}
                                </option>
                            </select>
                        </div>
                        
                    </div>
                    <div  class="side-item">
                       <div  class="nav-add-btn" *ngIf="othermenu == ''">
                            <a (click)="scrollToId('Request_a_demo')" class="default-btn border-radius" >{{'request_meeting' | translate }} </a>
                        </div>
                        <div  class="nav-add-btn" *ngIf="othermenu != ''">
                            <a routerLink="/" (click)="MovetoSection('Request_a_demo')" class="default-btn border-radius" >{{'request_meeting' | translate }} </a>
                        </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>

    
</div>

