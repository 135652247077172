import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  response: any;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  link: any = environment.backendUrl
  constructor(private http:HttpService) { 
    this.getListing();
  }

  ngOnInit(): void {
  }

  getListing(){
    
    this.http.getToken('blog/count').subscribe((res:any) => {
      this.count = res.count;

    })
    this.http.getToken('blog/list?page='+this.page).subscribe((resp:any) => {
      this.response = resp;
    })
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getListing();
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getListing();
  }

  removeBlog(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      //text: 'This process is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think',
    }).then((result) => {
      if (result.value) {
        this.http.get('blog/delete/'+id).subscribe((res:any) => {
          if(res.status == 1) {
            this.getListing()
            Swal.fire('Removed!', 'Blog removed successfully.', 'success');
          }
        })
      }
    });
  }
}
