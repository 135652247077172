<app-navbar-style-one></app-navbar-style-one>
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'blog_details' | translate }}</h3>
            <ul>
                <li><a routerLink="/">{{'home' | translate }}</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li><a routerLink="/blog">{{'blog' | translate }} </a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>{{'blog_details' | translate }}</li>
            </ul>
        </div>
    </div>
</div>





<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-article">
                    <div class="article-comment-area">
                        <div class="article-img">
                            <div id="owl-demo" class="owl-carousel owl-theme">
          
                                <div class="item">
                                    <img src="{{siteurl}}{{bloginfo.image}}" alt="Owl Image">
                                </div>
                                <div class="item" *ngFor="let multimg of mutliImages;">
                                    <img src="{{siteurl}}{{multimg.image}}" alt="Owl Image">
                                </div>
                                

                               
                              </div>
                        </div>

                        <ul class="article-comment">
                            <li>
                                <div>
                                    <h3>{{'by_zik' | translate }}</h3>
                                    <span>{{ bloginfo.created_date | date: 'MMMM dd, yyyy' }}</span>
                                </div>
                            </li>

                      

                            <li>
                                <div>
                                    <h3>{{'tags' | translate }}</h3>
                                    <span>{{firstTag}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
            
                    <div class="article-content">

                        <h3 *ngIf="directionController.textDirection == 'ltr'">{{bloginfo.title}}</h3>
                        <h3 *ngIf="directionController.textDirection == 'rtl'">{{bloginfo.he_title}}</h3>
                        <div class="content-text" *ngIf="directionController.textDirection == 'ltr'" [innerHTML]="bloginfo.description"></div>

                        <div class="content-text" *ngIf="directionController.textDirection == 'rtl'" [innerHTML]="bloginfo.he_designation"></div>
                       
                        <div class="blog-tag">
                            <ul>
                                <li class="active">{{'tags' | translate }}:</li>
                                <li *ngFor="let multiTag of multiTags"><a >#{{multiTag}}</a></li>
                            </ul>
                        </div>
                    </div>

                

                    

                    
                </div>
            </div>

            
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>