import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { environment } from '../../../../environments/environment';
import { TextDirectionController } from '../../../shared/TextDirectionController';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  response: any;
  page: number = 1;
  count: number = 0;
  tableSize: number = 12;
  tableSizes: any = [3, 6, 9, 12];
  link: any = environment.backendUrl
  public directionController = new TextDirectionController();
  constructor(private http:HttpService) {

   }

  ngOnInit(): void {
    this.getListing();
  }

  getListing(){
    
    this.http.get('blog/count').subscribe((res:any) => {
      this.count = res.count;

    })
    this.http.get('blog/listing?page='+this.page).subscribe((resp:any) => {
      this.response = resp;
    })
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getListing();
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getListing();
  }
}
