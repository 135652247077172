import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  post(serviceName: string, data: any){
    const headers = {'Content-Type': 'application/json'};
    const options = { headers: new HttpHeaders(headers)};
    const url = environment.apiUrl+serviceName;
    return this.http.post(url, data, options);
  }

  get(serviceName: string){
    const headers = {'Content-Type': 'application/json'};
    const url = environment.apiUrl+serviceName;
    return this.http.get(url, {headers});
  }

  postToken(serviceName: string, data: any){
    const token =  localStorage.getItem('auth_token');
    const headers = {'Content-Type': 'application/json','Authorization': token};
    const options = { headers: new HttpHeaders(headers)};
    const url = environment.apiUrl+serviceName;
    return this.http.post(url, data, options);
  }

  getToken(serviceName: string){
    const token =  localStorage.getItem('auth_token');
    const headers = {'Content-Type': 'application/json','Authorization': token};
    const url = environment.apiUrl+serviceName;
    return this.http.get(url, {headers});
  }
}
