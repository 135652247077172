<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Profile Update</h3>
        </div>
        
       
    </div>
</div>

<div class="listing-widget-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="comments-form">
                <div class="contact-form">
                    <h2>Profile Update</h2>

                    <form id="contactForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()" autocomplete="off"> 
                        <div class="row">
                            <div class="col-lg-12 col-sm-12"> 
                                <div class="form-group">
                                    <input type="email" name="email" class="form-control"  placeholder="Enter Email" formControlName="email"  >

                                    <div *ngIf="isSubmitted && errorControl.email.errors?.required" class="error-msg">
                                        <label class="error">Email is required</label>
                                      </div>  

                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="password" name="pwd" class="form-control"  placeholder="Enter new password (If you want changes password then please enter new password)" formControlName="password"  autocomplete="new-password"> 
                                </div>
                                
                            </div>
                          
                            <div class="col-lg-2 col-md-2">
                                <button type="submit" class="default-btn border-radius">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>