<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Meeing Listing</h3>
        </div>
        
       
    </div>
</div>

<div class="listing-widget-section pt-100 pb-70">
    <div class="container">
        <div class="row">
          
           <div class="table-responsive">    
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Created Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="
                    let res of response
                      | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                          };
                    let i = index
                  ">
                        <th scope="row">{{ res.id }}</th>
                        <td>{{ res.name }}</td>
                        <td>{{ res.email }}</td>
                        <td>{{ res.phone }}</td>
                        <td>{{ res.created_date | date: 'MMMM dd, yyyy' }}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-center">
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      (pageChange)="onTableDataChange($event)"
                    >
                    </pagination-controls>
                  </div>
            </div>  
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>