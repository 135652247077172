import { Component, OnInit } from '@angular/core';
import { Editor,Toolbar  } from 'ngx-editor';
import { HttpService } from '../../../services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
 
  userinfo: any;
  profileForm: any = FormGroup;
  isSubmitted = false;
  loading = false;

  constructor(private http:HttpService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['']
    });

    this.userinfo =  JSON.parse(localStorage.getItem('currentUser'));
    console.log(this.userinfo.email)
    this.profileForm.setValue({
      email: this.userinfo.email,
      password: ''
    });
  }

  onSubmit() {
    
    this.isSubmitted = true;
    
    if (this.profileForm.invalid) {
      return;
    }
    this.loading = true;
    const Userinfo = {
      id: this.userinfo.id,
      email: this.profileForm.value.email,
      password: this.profileForm.value.password,
      oldpassword: this.userinfo.password,
    };

    this.http.postToken('profile/update',Userinfo).subscribe((res: any) => {
      if(res.status == 'error') {
        Swal.fire(
          'Cancelled',
          res.message,
          'error'
        )
      }else {
        //location.href = '/listing'
        this.userinfo.email = res.email;
        this.userinfo.password = res.password;
        localStorage.setItem('currentUser', JSON.stringify( this.userinfo));
        Swal.fire( '','Profile Updated successfully', 'success');
       
      }
      this.loading = false;
    }, err=> {
      console.log(err.status)
      if(err.status == 0) {
        Swal.fire(
          '',
          'some technical issue please try again later',
          'error'
        )
      }
      this.loading = false;
    })
  }

  get errorControl() {
    return this.profileForm.controls;
  }

  
}
