import { Component, OnInit } from '@angular/core';
import { Editor,Toolbar  } from 'ngx-editor';
import { HttpService } from '../../../services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.scss']
})
export class EditBlogComponent implements OnInit {
  imageSrc: any;
  editImageSrc: any;
  images : any = [];
  editImages : any = [];
  editor: Editor;
  heEditor: Editor;
  englishdescription = '';
  hebrwdescription = '';
  response: any;
  id: any;
  siteurl: any = environment.backendUrl
  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear'],
  ];

  blogForm: any = FormGroup;
  isSubmitted = false;
  loading = false;


  
  constructor(private http:HttpService,private formBuilder: FormBuilder,private route: ActivatedRoute,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.heEditor = new Editor();

    this.blogForm = this.formBuilder.group({
      en_title: ['', Validators.required],
      he_title: ['', Validators.required],
      tags: '',
      englishdescription: '',
      hebrwdescription: ''
    });
    this.id = this.route.snapshot.params.id;
    this.getBlogInfo();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    
  }

  onSubmit() {
    
    this.isSubmitted = true;
    
    if (this.blogForm.invalid) {
      return;
    }
    this.spinner.show();
    const blogInfo = {
      en_title: this.blogForm.value.en_title,
      he_title: this.blogForm.value.he_title,
      en_description: this.blogForm.value.englishdescription,
      he_description: this.blogForm.value.hebrwdescription,
      tags: this.blogForm.value.tags,
      file: this.imageSrc,
      files: this.images,
      id: this.id
    };

    this.http.postToken('blog/update',blogInfo).subscribe((res: any) => {
      if(res.status == 'error') {
        Swal.fire(
          'Cancelled',
          res.message,
          'error'
        )
      }else {
        location.href = '/listing'
        Swal.fire( '','Blog updated successfully', 'success');
       
      }
      this.spinner.hide();
    }, err=> {
      console.log(err.status)
      this.spinner.hide();
      if(err.status == 0) {
        Swal.fire(
          '',
          'some technical issue please try again later',
          'error'
        )
      }
      this.loading = false;
    })
  }

  get errorControl() {
    return this.blogForm.controls;
  }

  onFileChange(event:any) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.imageSrc = reader.result as string;
        this.editImageSrc = '';
      };
   
    }
  }

  onFileChangeMulti(event:any) {
    this.images = [];
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
     
                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.images.push(event.target.result); 
                }
    
                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }

  getBlogInfo() {
    let weburl = this.siteurl;
    this.http.getToken('blog/find/'+this.id).subscribe((resp:any) => {
      this.blogForm.setValue({
        en_title: resp.title, 
        he_title: resp.he_title, 
        englishdescription: resp.description, 
        hebrwdescription: resp.he_designation, 
        tags: resp.tags, 
      });
      this.editImageSrc = weburl+resp.image; 
    })
    this.http.getToken('blog/images/'+this.id).subscribe((res:any) => {
      this.editImages = res
    })
  }

  removeImages(id: any) {
    //alert(id)
    Swal.fire({
      title: 'Are you sure?',
      //text: 'This process is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think',
    }).then((result) => {
      if (result.value) {
        this.http.get('blog/images/delete/'+this.id).subscribe((res:any) => {
          if(res.status == 1) {
            this.getBlogInfo()
            Swal.fire('Removed!', 'Images removed successfully.', 'success');
          }
        })
      }
    });
  }
}

