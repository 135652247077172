<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Testimonials</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Testimonials</li>
            </ul>
        </div>
    </div>
</div>

<div class="client-area pt-100">
    <div class="container">
        <div class="client-bg">
            <div class="client-slider owl-carousel owl-theme">
                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Oli Rubion</h3>
                                <span>Rubion Inc</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Sanaik Tubi</h3>
                                <span>Arbon Restaurant</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Mashrof Ruin</h3>
                                <span>Pice Cafe</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="testimonial-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Testimonials</span>
            <h2>What Our <b>Clients Say</b></h2>
        </div>
        
        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Sanaik Tubi</h3>
                    <span>Arbon Restaurant</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client1.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Oli Rubion</h3>
                    <span>Rubion Inc</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client3.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Mashrof Ruin</h3>
                    <span>Pice Cafe</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client2.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h3>Steven Smith</h3>
                    <span>Arbon Restaurant</span>
                    <p>Roinin ipsum dolor sit amet, consectetur adipisicing  sit ut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                    <ul class="rating">
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <div class="testimonial-top"><img src="assets/img/testimonial/client1.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>