<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>{{'blog' | translate }}</h3>
            <ul>
                <li><a routerLink="/">{{'home' | translate }}</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>{{'pages' | translate }}</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>{{'blog' | translate }}</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>{{'blog' | translate }}</span>
            <h2>{{'latest_blog' | translate }} <b>{{'post' | translate }}</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6"
            [dir] = "directionController.textDirection"
            *ngFor="
                    let res of response
                      | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                          };
                    let i = index
                  "
            >
                <div class="blog-card">
                    <a  routerLink="/blog-details/{{ res.id }}"><img src="{{link}}{{ res.image }}" alt="Images"></a>
                    <div class="content">
                        <span>{{ res.created_date | date: 'MMMM dd, yyyy' }} </span>
                        <h3 *ngIf="directionController.textDirection == 'ltr'"><a routerLink="/blog-details/{{ res.id }}">{{ res.title }} </a></h3>  
                        <h3 *ngIf="directionController.textDirection == 'rtl'"><a routerLink="/blog-details/{{ res.id }}">{{ res.he_title }} </a></h3>  
                        <a routerLink="/blog-details/{{ res.id }}" class="read-more">{{'read_more' | translate }}</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 d-flex justify-content-center">
                <pagination-controls
                  previousLabel="Prev"
                  nextLabel="Next"
                  (pageChange)="onTableDataChange($event)"
                >
                </pagination-controls>
              </div>
             <div class="col-lg-12 col-md-12" style="display: none;">
                 <div class="pagination-area text-center">
                    <a routerLink="/blog" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog" class="page-numbers">2</a>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                 </div>
             </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>