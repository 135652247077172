import { Component, OnInit } from '@angular/core';
import { Editor,Toolbar  } from 'ngx-editor';
import { HttpService } from '../../../services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss']
})
export class AddBlogComponent implements OnInit {
  imageSrc: any;
  images : any = [];
  editor: Editor;
  heEditor: Editor;
  englishdescription = '';
  hebrwdescription = '';
  toolbar: Toolbar = [
    // default value
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
    ['horizontal_rule', 'format_clear'],
  ];

  blogForm: any = FormGroup;
  isSubmitted = false;
  loading = false;

  constructor(private http:HttpService,private formBuilder: FormBuilder,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.heEditor = new Editor();

    this.blogForm = this.formBuilder.group({
      en_title: ['', Validators.required],
      he_title: ['', Validators.required],
      tags: '',
      englishdescription: '',
      hebrwdescription: ''
    });

  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  onSubmit() {
    
    this.isSubmitted = true;
    
    if (this.blogForm.invalid) {
      return;
    }
    this.spinner.show();
    const blogInfo = {
      en_title: this.blogForm.value.en_title,
      he_title: this.blogForm.value.he_title,
      en_description: this.blogForm.value.englishdescription,
      he_description: this.blogForm.value.hebrwdescription,
      tags: this.blogForm.value.tags,
      file: this.imageSrc,
      files: this.images,
    };

    this.http.postToken('blog/create',blogInfo).subscribe((res: any) => {
      if(res.status == 'error') {
        Swal.fire(
          'Cancelled',
          res.message,
          'error'
        )
      }else {
        location.href = '/listing'
        Swal.fire( '','Blog created successfully', 'success');
       
      }
      this.spinner.hide();
    }, err=> {
      this.spinner.hide();
      console.log(err.status)
      if(err.status == 0) {
        Swal.fire(
          '',
          'some technical issue please try again later',
          'error'
        )
      }
      this.loading = false;
    })
  }

  get errorControl() {
    return this.blogForm.controls;
  }

  onFileChange(event:any) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.imageSrc = reader.result as string;
     
        // this.myForm.patchValue({
        //   fileSource: reader.result as string
        // });
   
      };
   
    }
  }

  onFileChangeMulti(event:any) {
    this.images = [];
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
     
                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.images.push(event.target.result); 
   
                  //  this.myForm.patchValue({
                  //     fileSource: this.images
                  //  });
                }
    
                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }
}
