<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Blog Listing</h3>
        </div>
        
       
    </div>
</div>

<div class="listing-widget-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-2">
                <a routerLink="/add-blog" class="default-btn border-radius">Add Blog <i class='bx bx-plus'></i> </a>
            
            </div>
           <div class="table-responsive">    
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Image</th>
                        <th scope="col">Title English</th>
                        <th scope="col">Title Hebrw</th>
                        <th scope="col">Tags</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="
                    let res of response
                      | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                          };
                    let i = index
                  ">
                        <th scope="row">{{ res.id }}</th>
                        <td><img src="{{link}}{{ res.image }}"  style="height: 35px;"></td>
                        <td>{{ res.title }}</td>
                        <td>{{ res.he_title }}</td>
                        <td>{{ res.tags }}</td>
                        <td>{{ res.created_date | date: 'MMMM dd, yyyy' }}</td>
                        <td>
                            <a routerLink="/edit-blog/{{ res.id }}" class="btn btn-success"><i class='bx bx-edit'></i> </a>

                            &nbsp;&nbsp;&nbsp;
                            <button type="button" class="btn btn-danger" (click)="removeBlog(res.id)"> <i class='bx bx-trash'></i> </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-center">
                    <pagination-controls
                      previousLabel="Prev"
                      nextLabel="Next"
                      (pageChange)="onTableDataChange($event)"
                    >
                    </pagination-controls>
                  </div>
            </div>  
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>