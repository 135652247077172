import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../services/http.service';
import { TextDirectionController } from '../../../shared/TextDirectionController';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  id: any;
  bloginfo: any;
  mutliImages: any;
  firstTag :any;
  multiTags :any;
  siteurl: any = environment.backendUrl
  public directionController = new TextDirectionController();
  constructor(private route: ActivatedRoute,private http:HttpService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getBlogInfo()
  }

  getBlogInfo() {
    this.http.get('blog/find/'+this.id).subscribe((resp:any) => {
      this.bloginfo = resp

      this.multiTags = this.bloginfo.tags.split(',');
      this.firstTag = this.multiTags.shift();
    })
    this.http.get('blog/images/'+this.id).subscribe((res:any) => {
      console.log(res)
      this.mutliImages = res
    })
  }

  
}
