<app-navbar-style-one></app-navbar-style-one>

<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="error-content">
                <img src="assets/img/logo/logo2.png" alt="Images">
                <h1>4 <span>0</span> 4</h1>
                <h3>Oops! Page Not Found</h3>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <a routerLink="/" class="default-btn">Return To Home Page</a>
            </div>
        </div>
    </div>
</div>