import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TextDirectionController } from '../../../shared/TextDirectionController';
import { ScrollService } from '../../../services/scroll.service';
import { Router,NavigationEnd  } from '@angular/router';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {
  currentlang: any;
  othermenu: any = '';
  public directionController = new TextDirectionController();
  constructor(
    public translate: TranslateService,
    private scrollService: ScrollService,
    private router: Router
  ) {
    translate.addLangs(['en', 'il']);
    let lang  = localStorage.getItem('lang');
    if(lang == '' || lang == 'null' || lang == null) {
      lang = 'il';
      translate.setDefaultLang(lang);
      localStorage.setItem('lang',lang)
      window.location.reload();
    }else {
      translate.setDefaultLang(lang);
      localStorage.setItem('lang',lang)
    }
    
    
    this.directionController.CheckDiriction();
    this.currentlang = lang
    
  }

  

  ngOnInit(): void {
    if(this.router.url != '/') {
      this.othermenu = 'y'
    }else {
      this.othermenu = ''
    }

    setTimeout(() => {
      let moveto  = localStorage.getItem('moveto');
      if(moveto != '') {
        localStorage.setItem('moveto','')
        this.scrollToId(moveto)
      }
    }, 1500);
    
  }

  switchLang(lang: any) {
    this.translate.use(lang);
    localStorage.setItem('lang',lang)
    this.directionController.CheckDiriction();
    window.location.reload();
  }


  scrollToId(id: string) {
    console.log("element id : ", id);
    this.scrollService.scrollToElementById(id);
  }

  MovetoSection(id: string) {
    localStorage.setItem('moveto',id)
  }
}
