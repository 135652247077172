<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Pricing</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pricing</li>
            </ul>
        </div>
    </div>
</div>

<section class="pricing-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Pricing</span>
            <h2>Our Pricing Tables</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-deal"></i>
                        <h3>Basic Package</h3>
                        <h2><sup>$</sup>60</h2>
                        <span>Only this week</span>
                    </div>
                    <ul>
                        <li>One Listing</li>
                        <li>90 Days Availability</li>
                        <li>Non-Featured</li>
                        <li>Limited Support</li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Choose Professional <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-megaphone"></i>
                        <h3>Extended Package</h3>
                        <h2><sup>$</sup>105</h2>
                        <span>Only this month</span>
                    </div>
                    <ul>
                        <li>Ten Listings</li>
                        <li>Lifetime Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Choose Professional <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="price-card">
                    <div class="content">
                        <i class="flaticon-diamond"></i>
                        <h3>Professional Package</h3>
                        <h2><sup>$</sup>134</h2>
                        <span>Only this year</span>
                    </div>
                    <ul>
                        <li>Unlimited listing</li>
                        <li>Lifetime Availability</li>
                        <li>Featured In Search Results</li>
                        <li>24/7 Support</li>
                    </ul>
                    <a routerLink="/contact" class=" default-btn border-radius">Choose Professional <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>