import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-meeting-listing',
  templateUrl: './meeting-listing.component.html',
  styleUrls: ['./meeting-listing.component.scss']
})
export class MeetingListingComponent implements OnInit {
  response: any;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  link: any = environment.backendUrl
  constructor(private http:HttpService) { 
    this.getListing();
  }

  ngOnInit(): void {
  }

  getListing(){
    
    this.http.getToken('meeting/count').subscribe((res:any) => {
      this.count = res.count;

    })
    this.http.getToken('meeting/list?page='+this.page).subscribe((resp:any) => {
      this.response = resp;
    })
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getListing();
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.getListing();
  }

  
}
