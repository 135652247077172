<footer class="footer-area footer-bg2">
    <div class="footer-middle pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-widget">
                        <a routerLink="/" class="logo"><img src="assets/img/logo/logo1.png" alt="Logo"></a>
                        <p>Pilar Financial Services, Inc. 200 Wood Avenue South</p>
                        <ul class="footer-contact-list">
                            <li>Wonder Street, USA, New York</li>
                            <li><span>Phone :</span> <a href="tel:2151234567"> 215 - 123 - 4567</a></li> 
                            <li><span>Email :</span> <a href="mailto:info@pilar.com"> info@pilar.com</a></li> 
                        </ul>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget pl-1">
                        <h3>SUPPORT</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">My Account</a></li>
                            <li><a routerLink="/checkout">Checkout</a></li>
                            <li><a routerLink="/cart">Cart</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/login-register">Register</a></li>
                            <li><a routerLink="/">Help & Support</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget pl-5">
                        <h3>QUICK LINKS</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/about">About Us</a></li>
                            <li><a routerLink="/listing">Listing</a></li>
                            <li><a routerLink="/">How It Works</a></li>
                            <li><a routerLink="/faq">FAQ's</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li><a routerLink="/login-register">Register</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget">
                        <h3>NEWSLETTER</h3>
                        <p>To get the latest news and latest updates from us</p>
                        <div class="footer-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Your Email*">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">SUBSCRIBE now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copy-right-area copy-right-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="copy-right-list">
                    <ul>
                        <li><a routerLink="/terms-condition" target="_blank">Terms of Use</a></li> 
                        <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li> 
                        <li><a routerLink="/blog" target="_blank">Blog</a></li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-8 col-md-8">
                <div class="copy-right-text text-end">
                    <p>© Pilar is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div>
            </div>
        </div>
    </div>
</div>