<app-navbar-style-two></app-navbar-style-two>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-banner-title text-center">
            <h3>Edit Blog</h3>
        </div>
        
       
    </div>
</div>

<div class="listing-widget-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="comments-form">
                <div class="contact-form">
                    <h2>Edit Blog</h2>

                    <form id="contactForm" [formGroup]="blogForm" (ngSubmit)="onSubmit()"> 
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="en_title" class="form-control"  placeholder="English Title" formControlName="en_title">

                                    <div *ngIf="isSubmitted && errorControl.en_title.errors?.required" class="error-msg">
                                        <label class="error">English title is required</label>
                                      </div>  

                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="he_title" class="form-control"  placeholder="Hebrw Title" formControlName="he_title"> 
                                </div>
                                <div *ngIf="isSubmitted && errorControl.he_title.errors?.required" class="error-msg">
                                    <label class="error">Hebrw title is required</label>
                                  </div>  
                            </div>
                            <div class="col-lg-12 col-md-12">    
                                <div class="NgxEditor__Wrapper">
                                    <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" > </ngx-editor-menu>
                                    <ngx-editor [editor]="editor"  [ngModel]="englishdescription" [disabled]="false" [placeholder]="'English Description...'" formControlName="englishdescription"></ngx-editor>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">    
                                <div class="NgxEditor__Wrapper">
                                    <ngx-editor-menu [editor]="heEditor" [toolbar]="toolbar" > </ngx-editor-menu>
                                    <ngx-editor [editor]="heEditor"  [ngModel]="hebrwdescription" [disabled]="false" [placeholder]="'Hebrw Description...'" formControlName="hebrwdescription"></ngx-editor>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="tags" class="form-control"  placeholder="Enter Tags (Example: tag1, tag2, tag3 )" formControlName="tags">
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <label for="comment">Main Image:</label>
                                    <input type="file" name="file" class="form-control" (change)="onFileChange($event)">
                                 
                                    
                                </div>

                                <div class="selectImage" *ngIf="imageSrc">
                                    <img [src]="imageSrc" class="main_image" *ngIf="imageSrc" >
                                </div>   

                                <div class="selectImage" *ngIf="editImageSrc">
                                    <img [src]="editImageSrc" class="main_image" *ngIf="editImageSrc" >
                                </div>   
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <label for="comment">Multi  Image:</label>
                                    <input type="file" name="files[]" class="form-control" multiple="" (change)="onFileChangeMulti($event)" >

                                    <div class="multi-image">
                                        <div class="selectImage"*ngFor='let url of images'>
                                            <img [src]="url" class="main_image"  >
                                        </div> 
                                        <div class="selectImage"*ngFor='let multiimg of editImages'>
                                            <img [src]="siteurl+multiimg.image" class="main_image"  >
                                            <button type="button" class="btn btn-danger btn-sm" (click)="removeImages(multiimg.id)" > Remove </button>
                                        </div> 
                                    </div>

                                      
                                </div>


                            </div>
                            <div class="col-lg-2 col-md-2">
                                <a routerLink="/listing" class="default-btn border-radius">Cancel </a>
                            </div>
                            <div class="col-lg-2 col-md-2">
                                <button type="submit" class="default-btn border-radius">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>