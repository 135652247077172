import { Component, OnInit } from '@angular/core';
import { TextDirectionController } from '../../../shared/TextDirectionController';
import { ScrollService } from '../../../services/scroll.service';
import { HttpService } from '../../../services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  meetingForm: any = FormGroup;
  isSubmitted = false;
  loading = false;
  
  public directionController = new TextDirectionController();
  constructor(private scrollService: ScrollService,private http:HttpService,private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.meetingForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      name: ['', Validators.required],
      phone: ['', Validators.required],
    });
  }

  scrollToId(id: string) {
    console.log("element id : ", id);
    this.scrollService.scrollToElementById(id);
  }

  onSubmit() {
    
    this.isSubmitted = true;
    if (this.meetingForm.invalid) {
      return;
    }
    this.loading = true;
    const meetingInfo = {
      email: this.meetingForm.value.email,
      name: this.meetingForm.value.name,
      phone: this.meetingForm.value.phone
    };

    this.http.post('meeting/create',meetingInfo).subscribe((res: any) => {
      if(res.status == 'error') {
        Swal.fire(
          'Cancelled',
          res.message,
          'error'
        )
      }else {
        
        Swal.fire( '','Thanks for your message. Our expert will contact you soon! Have a great day!', 'success');
        this.meetingForm.reset();

        this.meetingForm.get('name').clearValidators();
        this.meetingForm.get('name').updateValueAndValidity();
        this.meetingForm.get('email').clearValidators();
        this.meetingForm.get('email').updateValueAndValidity();
        this.meetingForm.get('phone').clearValidators();
        this.meetingForm.get('phone').updateValueAndValidity();
      }
      this.loading = false;
    }, err=> {
      console.log(err.status)
      if(err.status == 0) {
        Swal.fire(
          '',
          'some technical issue please try again later',
          'error'
        )
      }
      this.loading = false;
    })
  }

  get errorControl() {
    return this.meetingForm.controls;
  }
}
