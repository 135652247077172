<app-navbar-style-one></app-navbar-style-one>


<div class="inner-banner item-bg1" [dir] = "directionController.textDirection">
    <div class="container">
        <div class="inner-title text-center">
            <h1 class="slider_title"><b> {{'slider_title' | translate }}</b></h1>
            <div class="et_pb_slide_content">
                <span role="presentation">{{'slider_analyze' | translate }}</span>
                <span role="presentation"> <span class="dot"></span>{{'slider_plan_smart' | translate }}</span>
                <span role="presentation"><span class="dot"> </span>{{'slider_deliver_fast' | translate }}</span>
            </div>

            <div class="et_pb_button_wrapper" [dir] = "directionController.textDirection">
                <a (click)="scrollToId('Request_a_demo')" class="default-btn border-radius" >{{'request_meeting' | translate }} </a>
            </div>
        </div>
    </div>
</div>

<!--<div class="slider-area owl-carousel owl-theme" [dir] = "directionController.textDirection">
    <div class="slider-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1><b class="slider_title"> {{'slider_title' | translate }}</b></h1>
                        <div class="et_pb_slide_content">
                            <span role="presentation">{{'slider_analyze' | translate }}</span>
                            <span role="presentation"> <span class="dot"></span>{{'slider_plan_smart' | translate }}</span>
                            <span role="presentation"><span class="dot"> </span>{{'slider_deliver_fast' | translate }}</span>
                        </div>

                        <div class="et_pb_button_wrapper">
                            <a (click)="scrollToId('Request_a_demo')" class="default-btn border-radius">Lets Talk! </a>
                        </div>

                       
                    </div>
                </div>
            </div>
        </div>
    </div>

    

   
</div>-->

<section class="city-area process-bg ptb-100" id="Industries" [dir] = "directionController.textDirection"> 
    <div class="container">
        <div class="section-title text-center">
            <h1>{{'service_title' | translate }}</h1>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-6"> 
                <div class="city-item">
                    <img src="assets/img/original/_Distribution_and_logistics_companies.png" class="servic_image_content" alt="Images">
                    <h3 class="city-location">{{'service_content_1' | translate }}</h3>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="city-item">
                    <img src="assets/img/original/Online_stores_and_retailers_that_make_deliveries.png" class="servic_image_content" alt="Images">
                    <h3 class="city-location">{{'service_content_2' | translate }}</h3>
                </div>
            </div>

        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-position icon-bg"></i>
                    <p>{{'service_sub_content_1' | translate }}</p>
                    <div class="process-number active">1</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="process-card">
                    <i class="flaticon-to-do-list icon-bg"></i>
                    <p>{{'service_sub_content_2' | translate }}</p>
                    <div class="process-number active">2</div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="process-card">
                    <i class="flaticon-box icon-bg"></i>
                    <p>{{'service_sub_content_4' | translate }}</p>
                    <div class="process-number active">3</div>
                </div>
            </div>
        </div>

        <!--<div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="process-card">
                    <div class="sub_image_content">
                        <div class="servic_sub_image_content">
                            <img src="assets/img/service/efficiency.png" >
                        </div>
                    </div>
                    
                    <p>{{'service_sub_content_1' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="process-card">
                    <div class="sub_image_content">
                        <div class="servic_sub_image_content">
                            <img src="assets/img/service/Planning.png" >
                        </div>
                    </div>
                    <p>{{'service_sub_content_2' | translate }}</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="process-card">
                    <div class="sub_image_content">
                        <div class="servic_sub_image_content">
                            <img src="assets/img/service/Productivity.png" >
                        </div>
                    </div>
                    <p>{{'service_sub_content_3' | translate }}</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="process-card">
                    <div class="sub_image_content">
                        <div class="servic_sub_image_content">
                            <img src="assets/img/service/Time.png" >
                        </div>
                    </div>
                    <p>{{'service_sub_content_4' | translate }}</p>
                </div>
            </div>

        </div>-->


    </div>
</section>

<div class="about-area pt-100 pb-70" id="Solutions"  [dir] = "directionController.textDirection">
    <div class="container">
        <div class="section-title text-center">
            <h1>{{'our_solution' | translate }}</h1>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <p class="our_solution_paragraph"> {{'our_solution_content' | translate }}</p>
                    </div>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/original/our_solution.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>


<section class="testimonial-area-three process-bg  pt-100 pb-70" id="What_we_do"  [dir] = "directionController.textDirection">
    <div class="container">
        <div class="section-title text-center">
            <h1>{{'what_title' | translate }}</h1> 
        </div>
        
        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_1' | translate }}</h2>  
                    <p>{{'what_content_1' | translate }}</p>
                    
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_2' | translate }}</h2>  
                    <p>{{'what_content_2' | translate }}</p>
                    
                </div>
            </div>

            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_3' | translate }}</h2>  
                    <p>{{'what_content_3' | translate }}</p>
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_4' | translate }}</h2>  
                    <p>{{'what_content_4' | translate }}</p>
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_5' | translate }}</h2>  
                    <p>{{'what_content_5' | translate }}</p>
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_6' | translate }}</h2>  
                    <p>{{'what_content_6' | translate }}</p>
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_7' | translate }}</h2>  
                    <p>{{'what_content_7' | translate }}</p>
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_8' | translate }}</h2>  
                    <p>{{'what_content_8' | translate }}</p>
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_9' | translate }}</h2>  
                    <p>{{'what_content_9' | translate }}</p>
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="testimonial-item testimonial-item-bg2">
                    <h2>{{'what_header_10' | translate }}</h2>  
                    <p>{{'what_content_10' | translate }}</p>
                    
                </div>
            </div>

            
        </div>
    </div>
</section>

<div class="about-area pt-100 pb-70" id="About_Zik"  [dir] = "directionController.textDirection">
    <div class="container">
        <div class="section-title text-center">
            <h1>{{'about_title' | translate }}</h1>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <p class="our_solution_paragraph"> {{'about_content_1' | translate }}</p>
                        <p class="our_solution_paragraph"> {{'about_content_2' | translate }}</p>
                        <p class="our_solution_paragraph"> {{'about_content_3' | translate }}</p>
                    </div>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/original/About_Zik.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>


<div class="request-demo process-bg pt-100 pb-70 {{directionController.textDirection}}" id="Request_a_demo"  [dir] = "directionController.textDirection">
    <div class="container">
        
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-title text-center">
                    <h1>{{'request_title' | translate }}</h1>
                </div>
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="meetingForm" (ngSubmit)="onSubmit()">
                       <div class="row">
                          <div class="col-lg-12 col-sm-12">
                             <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" name="name" id="name" class="form-control" required placeholder="{{'your_name' | translate }}*" formControlName="name">
                             </div>
                             <div *ngIf="isSubmitted && errorControl.name.errors?.required" class="error-msg">
                                <label class="error">Name is required</label>
                              </div>  
                             
                          </div>
                          <div class="col-lg-12 col-sm-12">
                             <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="email" name="email" id="email" class="form-control" required placeholder="{{'email' | translate }}*" formControlName="email">
                             </div>
                             
                              <div *ngIf="isSubmitted && errorControl.email.errors?.required" class="error-msg">
                                <label class="error">Email is required</label>
                              </div>
                              <div *ngIf="isSubmitted && errorControl.email.errors?.pattern" class="error-msg">
                                <label class="error">Please provide valid email id</label>
                              </div>
                          </div>
                          <div class="col-lg-12 col-sm-12">
                             <div class="form-group">
                                <i class='bx bx-phone'></i>
                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="{{'your_phone' | translate }}"  formControlName="phone">
                             </div>
                             <div *ngIf="isSubmitted && errorControl.phone.errors?.required" class="error-msg">
                                <label class="error">Phone is required</label>
                              </div>  
                          </div>
                          <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn border-radius"  [disabled]="loading">{{'submit' | translate }}</button>
                          </div>
                       </div>
                    </form>
                 </div>
            </div>

            <div class="col-lg-6">
                <div class="request-demo-content">
                    <p>{{'content_li_1' | translate }}</p>
                    <p>{{'content_li_2' | translate }}</p>
                    <p>{{'content_li_3' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
</div>






<app-footer-style-one></app-footer-style-one>