import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {
  loginForm: any = FormGroup;
  isSubmitted = false;
  constructor(private http:HttpService,private formBuilder: FormBuilder) { 
    let userinfo =  localStorage.getItem('currentUser');
    console.log(userinfo)
    if(userinfo !='' &&  userinfo != null) {
      location.href = '/listing'
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', Validators.required],
    });
  }

  onSubmit() {

    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const loginInfo = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    };

    this.http.post('user/authenticate',loginInfo).subscribe((res: any) => {
      if(res.status == 'error') {
        Swal.fire(
          'Cancelled',
          res.message,
          'error'
        )
      }else {
        localStorage.setItem('currentUser', JSON.stringify(res.data));
        localStorage.setItem('auth_token', res.token);
        location.href = '/listing'
      }

    }, err=> {
      console.log(err)
      console.log(err.status)
    })
  }

  get errorControl() {
    return this.loginForm.controls;
  }
}
