<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="inner-banner-text">
                        <h3>The Beauty Shop</h3>
                        <ul>
                            <li><i class="flaticon-cursor"></i>40 Square Plaza, NJ, USA</li>
                            <li><i class="flaticon-telephone"></i> <a href="tel:+6(567)876543">+6(567)876543</a></li>
                            <li><i class="flaticon-email-1"></i> <a href="#">pilar@domain.com</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="banner-rating">
                        <ul>
                            <li class="rating-star">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span>4 Review</span>
                            </li>
                            <li><a routerLink="/listing-details">4.9</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-bottom">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="banner-tag">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-favorite"></i>Book Now</a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-writing"></i> Add Review</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="banner-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3>
                        <div class="banner-status">
                            <a routerLink="/listing-details"><h3>Open Now</h3></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7">
                    <ul class="list">
                        <li><a href="index.html">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">The Beauty Shop</li>
                    </ul>
                </div>

                <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="listing-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="listing-details-wrap">
                    <div class="listing-category">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-touch"></i>DETAILS</a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-gallery"></i> GALLERY</a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-writing"></i> REVIEWS</a></li>
                        </ul>
                        <h3><i class="flaticon-worldwide"></i> MAP</h3>
                    </div>
                    <div class="listing-widget listing-widget-desc">
                        <h3 class="title">Description</h3>
                        <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidunt ut labore et dolore plicabo.Nemo enim ipsam voluptatem quia voluptas sit aspertur aut odit aut quia csqntur magni dolores eos qui ratione voluptatem sequi nesci</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore plicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur tur magni lores eos qui ratione voluptatem sequi nesciunt.</p>
                        <a routerLink="/listing-details" class="default-btn border-radius">Visit Website <i class='bx bx-plus'></i></a>
                        <div class="listing-features">
                            <h3 class="features-title">Listing Features</h3>
                            <div class="row">
                                <div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-coffee-cup"></i>
                                        <h3>Restaurant Inside</h3>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-wifi"></i>
                                        <h3>Free WiFi</h3>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-parking"></i>
                                        <h3>Free Parking</h3>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3">
                                    <div class="features-card">
                                        <i class="flaticon-bread"></i>
                                        <h3>Breakfast</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="listing-widget gallery-slider">
                        <h3 class="title">Gallery / Photos</h3>

                        <div class="gallery-slider-area owl-carousel owl-theme">
                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details1.jpg" alt="Images">
                            </div>

                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details2.jpg" alt="Images">
                            </div>

                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details3.jpg" alt="Images">
                            </div>

                            <div class="Gallery-item">
                                <img src="assets/img/place-list/listing-details4.jpg" alt="Images">
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget listing-map">
                        <h3 class="title">Map Location</h3>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d735257.9205168115!2d-75.33502765384733!3d40.30050562979537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2a07dcfd9d0ce20e!2sUs%20Rt%2040%20%40%20Governors%20Sq%20Plaza%202!5e0!3m2!1sen!2sbd!4v1596709319151!5m2!1sen!2sbd"></iframe>
                    </div>

                    <div class="listing-widget">
                        <h3 class="title">Item Reviews - 2</h3>

                        <div class="listing-widget-review">
                            <ul>
                                <li class="rating-star">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <span>Good Review</span>
                                </li>
                                <li><a routerLink="/listing-details">4.5</a></li>
                            </ul>
                        </div>

                        <div class="progressbar-area">
                            <div class="single-progressbar">
                                <h3>Quality</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-1">5.0</div>
                                    <div class="progressbar-circle pc-1"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Location</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-2">5.5</div>
                                    <div class="progressbar-circle pc-2"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Price</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-3">4.7</div>
                                    <div class="progressbar-circle pc-3"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Service</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-4">4.5</div>
                                    <div class="progressbar-circle pc-4"></div>
                                </div>
                            </div>
                        </div>

                        <div class="listing-comment">
                            <ul>
                                <li>
                                    <img src="assets/img/place-list/listing-details-pro1.png" alt="Image">
                                    <div class="content">
                                        <h3>Devit Killer</h3>
                                        <span>Jnauary 12, 2023</span>
                                        <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                        <div class="comment-rating"> 
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <span>4.5</span>
                                        </div>
                                        <a routerLink="/listing-details">Reply</a>
                                        <div class="view-list">
                                            Helpful review23
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <img src="assets/img/place-list/listing-details-pro2.png" alt="Image">
                                    <div class="content">
                                        <h3>Morah Jein </h3>
                                    <span>July 12, 2023</span>
                                    <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                        <div class="comment-rating"> 
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <span>4.5</span>
                                        </div>
                                        <a routerLink="/listing-details">Reply</a>
                                        <div class="view-list">
                                            Helpful review23
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="listing-widget progressbar-area-title">
                        <h3 class="title">Add Review</h3>

                        <div class="progressbar-area progressbar-bg">
                            <div class="single-progressbar">
                                <h3>Quality</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-1">5.0</div>
                                    <div class="progressbar-circle pc-1"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Location</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-2">5.5</div>
                                    <div class="progressbar-circle pc-2"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Price</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-3">4.7</div>
                                    <div class="progressbar-circle pc-3"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Service</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-4">4.5</div>
                                    <div class="progressbar-circle pc-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="listing-widget-contact">
                        <div class="listing-contact-title">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="listing-widget-mail">
                                        <i class="flaticon-email-2"></i>
                                        <div class="content">
                                            <h3>Don’t Worry</h3>
                                            <span>Your email is safe with us.</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 text-end">
                                    <div class="listing-contact-review">
                                        <ul>
                                            <li class="rating-star">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <span>Your Score</span>
                                            </li>
                                            <li><a href="#">4.5</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name*">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn border-radius">Submit Comment <i class='bx bx-plus'></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="listing-details-side">
                    <div class="listing-widget-side">
                        <h3 class="title">Booking System</h3>

                        <div class="listing-Booking-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name*">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='flaticon-email-2'></i>
                                            <input type="email" name="email" id="email" class="form-control" required placeholder="E-mail*">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-phone'></i>
                                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class="flaticon-calendar"></i>
                                            <input type="text" class="form-control" placeholder="Date & Time">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                        </div>
                                    </div>

                                    <div class="add-counter">
                                        <h3>Additional Service <br> 01</h3>

                                        <div class="form-input-counter">
                                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                        </div>
                                    </div>

                                    <div class="add-counter">
                                        <h3>Additional Service <br>01</h3>

                                        <div class="form-input-counter">
                                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <h3 class="price-rate">Total Cost <span>$50.00</span></h3>

                            <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Working Hours</h3>

                        <ul class="listing-widget-list">
                            <li>Monday <span>9:00 AM - 8:00 PM</span></li>
                            <li>Sunday <span>Open all day</span></li>
                            <li>Saturday <span>7:00 AM - 9:00 PM</span></li>
                            <li>Friday <span>Open all day</span></li>
                            <li>Wednesday <span>9:00 AM - 8:00 PM</span></li>
                            <li>Tuesday <span>Open all day</span></li>
                            <li>Monday <span>Open all day</span></li>
                        </ul>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Contact</h3>

                        <ul class="listing-widget-list">
                            <li>Address : <span> USA 27TH Brooklyn NY</span></li>
                            <li>Phone : <span><a href="tel:+3(567)347654">+3(567)347654</a></span></li>
                            <li>Mail : <span><a href="mailto:pilar@listing.com">pilar@listing.com</a></span></li>
                            <li>Website : <span> <a href="#">https://pilar.com</a></span></li>
                        </ul>

                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Price Range</h3>
                        
                        <h3 class="price-title">Price : <span>$ 67.00 - $ 390.00</span></h3>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">Hosted by :</h3>

                        <div class="listing-side-host">
                            <img src="assets/img/place-list/listing-details-pro3.png" alt="Images">
                            <h3>Jhon Kelvin</h3>
                            <span>20 PLACES HOSTED</span>
                        </div>

                        <ul class="side-host-list">
                            <li><a href="#"><i class='bx bxs-user'></i></a></li>
                            <li><a href="#"><i class='bx bx-envelope'></i></a></li>
                            <li> <span>Claim Now!</span></li>
                        </ul>
                    </div>

                    <div class="listing-widget-side">
                        <h3 class="title">News Tags</h3>

                        <ul class="listing-side-tag">
                            <li><a href="#">#Home</a></li>
                            <li><a href="#"><b>#Residence</b></a></li>
                            <li><a href="#">#Real estate</a></li>
                            <li><a href="#"><b>#Residence</b></a></li>
                            <li><a href="#">#Fancyhouse</a></li>
                            <li><a href="#">#Real estate</a></li>
                        </ul>
                    </div>

                    <div class="listing-widget-side listing-blog-post">
                        <h3 class="title">News Tags</h3>

                        <ul class="listing-widget-post">
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Hotel</span>
                                    <h4>Real Estate Is Being</h4>
                                    <p><i class="flaticon-cursor"></i> 8 BRIGHT ST, JERSEY CITY 07302, USA</p>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog2.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Residence</span>
                                    <h4>Place Of Expectation</h4>
                                    <p><i class="flaticon-cursor"></i> 70 BRIGHT ST NEW YORK USA</p>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog3.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Place</span>
                                    <h4>Came In The Place</h4>
                                    <p><i class="flaticon-cursor"></i> 78 HillStreet ST NEW YORK USA</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>